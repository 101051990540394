var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('n-panels',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('filters-panel',{attrs:{"entidad-id":_vm.$route.params.entidadId},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]},proxy:true},{key:"center",fn:function(){return [_c('promotions',{attrs:{"entidad-id":_vm.$route.query.organization || _vm.$route.params.entidadId}}),_c('n-grid',{attrs:{"label":_vm.$t('Home.title1'),"src":_vm.productsUrl,"filters":{
          'precioUnitario': _vm.filters.precioUnitario.join(':'),
          'producto': _vm.filters.producto,
          'tblLgtGrpProducto.grpProductoId': _vm.filters.categories.join(',')
        },"filter-operators":{
          'precioUnitario': 'between',
          'producto': 'like',
          'tblLgtGrpProducto.grpProductoId': 'in'
        },"order-by":"precioUnitario","sort-by":_vm.filters.sortBy},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('filters-panel',{attrs:{"entidad-id":_vm.$route.params.entidadId},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]},proxy:true},{key:"append-header",fn:function(ref){
        var isLoading = ref.isLoading;
return [_c('v-toolbar-items',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('AppBar.search'),"outlined":"","rounded":"","dense":"","clearable":"","hide-details":"","single-line":"","prepend-inner-icon":"mdi-magnify","disabled":isLoading},model:{value:(_vm.filters.producto),callback:function ($$v) {_vm.$set(_vm.filters, "producto", $$v)},expression:"filters.producto"}})],1)]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('card-cart',{attrs:{"card-hover":"","title":item.producto,"referencia":item.referencia,"productId":item.productoId,"amount":item.cantidadEmpaque,"store":_vm.$_.get(item, 'tblCnfComercio.comercio', 'Generico'),"salePrice":item.precioNeto,"content-img":item.imagen || '/img/default_product_image.png'},on:{"add":function (cb) {
              if (!_vm.isAuthenticated) {
                _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/guest")).catch(function () { });
              } else {
                _vm.openAppointmentsDialogSingle(item, cb);
              }
            },"wishlist":function (cb) { return _vm.addProductToWishList(item, cb); }}})]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }