<template>
  <v-card class="shadow-sm" v-if="promotions.length > 0">
    <v-card-text>
      <v-carousel height="auto" light hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(n, index) in promotions" :key="index">
          <v-container>
            <v-row class="fill-height">
              <v-col cols="12" md="7" lg="8" >
                <div class="text-h4" style="overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                  {{ n.promocion }}
                </div>
                <div class="caption grey--text">
                  Vigencia del {{ n.fechaVigenciaIni }} al {{ n.fechaVigenciaFin }}
                </div>
              </v-col>

              <v-col cols="12" md="5" lg="4" class="text-right">
                <v-img aspect-ratio="1" width="100%" max-width="100%" max-height="150px" height="150px" contain :src="n.imagen || '/img/default_promotion_image.png'" />
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    entidadId: {
      type: String
    }
  },
  data() {
    return {
      promotions: []
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get(`api/v1/public/entities/${this.entidadId}/promotions`).then((res) => {
          const promotions = _.get(res, 'data.data', []);

          this.promotions = promotions;
        }).catch(() => {
          this.promotions = [];
        });
    }
  }
}
</script>