<template>
  <base-card class="card-hover" :class="cardHoverShadow ? 'card-hover-shadow' : ''">


    <v-img height="200" :src="contentImg" :contain="true">
      <div class="card-hover-icon">
        <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 ">
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on" v-if="!disableView">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>

            <base-card>
              <v-card-title>
                <v-btn class="mt-4" absolute right icon color="primary" text @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-img height="200" :src="contentImg" contain />
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="mt-4 mb-4 text-h6 font-weight-black">
                      {{ title }}
                    </div>

                    <h5 class="grey--text text--darken-5 mb-3">
                      {{ $t('CardCart.stock') }}
                    </h5>
                    
                    <h5 class="grey--text text--darken-5 mb-3">
                      {{ $t('CardCart.description') }}
                      <span class="grey--text text--darken-1 font-weight-medium ml-2" v-html="referenciaLocal"></span>
                    </h5>

                    <h5 class="grey--text text--darken-5 mb-3">
                      {{ $t('CardCart.amount') }}:
                      <span class="grey--text text--darken-1 font-weight-medium ml-2">{{ amount }}</span>
                    </h5>

                    <h5 class="grey--text text--darken-5 mb-3">
                      {{ $t('CardCart.soldBy') }}:
                      <span class="grey--text text--darken-1 font-weight-medium ml-2">{{ store }}</span>
                    </h5>

                    <h2 class="font-weight-bold primary--text mb-3">
                      $ {{ salePrice.toFixed(2) }}
                    </h2>
                  </v-col>
                </v-row>
              </v-card-text>
            </base-card>
          </v-dialog>

          <v-btn icon color="primary" @click="() => addToWishlist()" :loading="adding" :disabled="adding">
            <v-icon>mdi-heart-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-img>

    <v-card-text class="d-flex justify-content-between align-end">
      <div class="flex-grow-1 my-3">
        <h6 class="mb-0 grey--text text--darken-4">
          <div class="card-title">
            {{ title }}
          </div>
        </h6>
        <div class="d-flex mb-1">
          {{ brand }}
        </div>
        <div class="d-flex">
          <h6 class="primary--text mr-2 mb-0" v-if="salePrice">
            $ {{ salePrice.toFixed(2) }}
          </h6>
          <h6 class="gray--text lighten-4 text-decoration-line-through mb-0" v-if="regularPrice != null">
            $ {{ regularPrice.toFixed(2) }}
          </h6>
        </div>
      </div>

      <div class="d-flex flex-column">
        <v-btn class="rounded" outlined fab x-small tile color="primary" @click="() => addItem()" v-if="!disableAdd"
          :disabled="loading" :loading="loading">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </base-card>
</template>
<script>
export default {
  props: {
    cardHoverShadow: {
      type: Boolean,
      default: false,
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    disableView: {
      type: Boolean,
      default: false,
    },
    contentImg: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Title',
    },
    productId: {
      type: String,
      default: 'ProductId',
    },
    description: {
      type: String,
      default: 'Description',
    },
    brand: {
      type: String,
      default: '',
    },
    store: {
      type: String,
      default: '',
    },
    amount: {
      type: [String, Number],
      default: '',
    },
    salePrice: {
      type: Number,
      default: 0,
    },
    regularPrice: {
      type: Number,
      default: null,
    },
    referencia: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      adding: false,
      referenciaLocal: this.referencia
    }
  },
  mounted(){
    this.addReferenceLineBreak();
  },
  methods: {
    addItem() {
      this.loading = true;

      this.$emit('add', () => {
        this.loading = false;
      });
    },
    addToWishlist() {
      this.adding = true;

      this.$emit('wishlist', () => {
        this.adding = false;
      });
    },
    addReferenceLineBreak() {
      this.referenciaLocal = (this.referenciaLocal || '').replace(/(Check in|Check out)/g, '<br>$1');
    },
  }
}
</script>

<style lang="scss" scoped>
.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card-hover {
  &:hover {
    .card-hover-icon {
      display: block;
    }
  }

  .card-hover-icon {
    display: none;
  }
}
</style>
