<template>
  <v-container>
    <n-panels>
      <template #left>
        <filters-panel v-model="filters" :entidad-id="$route.params.entidadId"></filters-panel>
      </template>

      <template #center>
        <promotions :entidad-id="$route.query.organization || $route.params.entidadId"></promotions>

        <n-grid
          :label="$t('Home.title1')"
          :src="productsUrl"
          :filters="{
            'precioUnitario': filters.precioUnitario.join(':'),
            'producto': filters.producto,
            'tblLgtGrpProducto.grpProductoId': filters.categories.join(',')
          }"
          :filter-operators="{
            'precioUnitario': 'between',
            'producto': 'like',
            'tblLgtGrpProducto.grpProductoId': 'in'
          }"
          order-by="precioUnitario"
          :sort-by="filters.sortBy"
        >
          <template #filters>
            <filters-panel v-model="filters" :entidad-id="$route.params.entidadId"></filters-panel>
          </template>
          
          <template #append-header="{ isLoading }">
            <v-toolbar-items>
              <v-text-field v-model="filters.producto" :placeholder="$t('AppBar.search')" outlined rounded dense
                clearable hide-details single-line prepend-inner-icon="mdi-magnify" :disabled="isLoading"></v-text-field>
            </v-toolbar-items>
          </template>

          <template #item="{ item }">
            <card-cart
              card-hover
              :title="item.producto"
              :referencia="item.referencia"
              :productId="item.productoId"
              :amount="item.cantidadEmpaque"
              :store="$_.get(item, 'tblCnfComercio.comercio', 'Generico')"
              :salePrice="item.precioNeto"
              :content-img="item.imagen || '/img/default_product_image.png'"
              @add="(cb) => {
                if (!isAuthenticated) {
                  $router.push(`/entities/${$route.params.entidadId}/guest`).catch(() => { });
                } else {
                  openAppointmentsDialogSingle(item, cb);
                }
              }"
              @wishlist="(cb) => addProductToWishList(item, cb)"
            />
          </template>
        </n-grid>
      </template>
    </n-panels>
  </v-container>
</template>

<script>
import NPanels from '../../components/newgen/NPanels';
import NGrid from "../../components/newgen/NGrid";
import CardCart from "../../components/cartCard/CardCart";
import NAppointmentDialog from "../../components/newgen/NAppointmentDialog";
import Promotions from './Promotions';
import FiltersPanel from "./FiltersPanel";
import { mapState } from 'vuex';
import _ from 'lodash';
import ProductMixins from '../../mixins/ProductMixins';

export default {
  mixins: [
    ProductMixins
  ],
  components: {
    NPanels,
    NGrid,
    CardCart,
    Promotions,
    FiltersPanel,
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
    productsUrl() {
      const params = this.$route.params;

      const entidadId = params.entidadId;

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/entities/${entidadId}/products`;
      } else {
        url += `/public/entities/${entidadId}/products`;
      }

      return url;
    }
  },
  data() {
    return {
      isSidebar: false,
      filters: {
        precioUnitario: [0, 10000],
        categories: [],
        sortBy: 'asc'
      },
      drawer: true,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
    };
  },
  methods: {
    openAppointmentsDialogSingle(i, cb) {

      // SE VERIFICA SI EL ITEM ES CALENDARIZABLE
      if (i.esAgendable === "Y") {
        cb();
        // SE CALENDARIZA EL ITEM
        this.$dialog({ items: [i], visible: true }, NAppointmentDialog)
          .then((res) => {

            // SE AGREGA EL ITEM AL CARRITO DE COMPRAS
            this.increaseQuantity(res[0])
              .then(() => {
                this.$notify({ text: this.$t("Home.cartNotification.success") });
              })
              .catch(() => this.$notify({ text: this.$t("Home.cartNotification.error") }))
              .finally(() => cb());
          });

      } else {

        // SE AGREGA EL ITEM AL CARRITO DE COMPRAS
        this.increaseQuantity(i)
          .then(() => {
            this.$notify({ text: this.$t("Home.cartNotification.success") });
          })
          .catch(() => this.$notify({ text: this.$t("Home.cartNotification.error") }))
          .finally(() => cb());
      }
    },
    hasServices(selected) {
      return _.sumBy(selected, function (a) {
        if (a.esAgendable === "Y") {
          return 1;
        } else {
          return 0;
        }
      });
    },
    addProductToWishList(item, cb) {
      if (this.isAuthenticated) {
        this.addToWishlist(item)
          .then(() => {
            this.$notify({ text: this.$t("Home.wishListNotification.success") });
          })
          .catch(() => this.$notify({ text: this.$t("Home.wishListNotification.error") }))
          .finally(() => cb());
      } else {
        this.$notify({ text: this.$t("Home.wishListNotification.noSession") })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box-sidebar {
  .sidebar-slider {
    .v-messages {
      display: none;
    }
  }
}
</style>